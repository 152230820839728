import IExternalIncome from '@/types/IExternalIncome';

export default class ExternalIncomeFactory {
  static toExternalIncome(data: any): IExternalIncome {
    return {
      countryFromId: data.countryFromId,
      countryFromName: data.countryFromName,
      countryToId: data.countryToId,
      countryToName: data.countryToName,
      currencyISO: data.currencyISO,
      externalIncomeId: data.externalIncomeId,
      incomeLimit: data.incomeLimit,
      month: data.month,
      value: data.value,
      vatDeclaration: data.vatDeclaration,
      vatValue: data.vatValue,
      year: data.year,

    };
  }
}
