
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import IExternalIncomeForm from '@/types/IExternalIncomeForm';
import ICountry from '@/types/ICountry';
import AuthService from '@/services/AuthService';
import {resolveError} from '@/utils/notifications';
import {price, required} from '@/utils/validationRules';
import DatePicker from '@/components/DatePicker.vue';
import ExternalIncomeService from '@/services/ExternalIncomeService';

@Component({
  name: 'ExternalIncomeForm',
  components: {DatePicker},
})
export default class ExternalIncomeForm extends Vue {
  @Prop()
  value!: boolean;

  isFirstLoad: boolean = true;
  loading: boolean = false;
  form: IExternalIncomeForm = {
    countryIdFrom: 0,
    countryIdTo: 0,
    month: null,
    value: null,
    year: null,
  };
  rules = {
    countryIdFrom: [required],
    countryIdTo: [required],
    month: [required],
    value: [required, price],
    year: [required],
  };

  countries: ICountry[] = [];

  get date() {
    if (this.form.month) {
      return `${this.form.year}-${String(this.form.month).padStart(2, '0')}`;
    }
    return '';
  }

  set date(value: string) {
    const [year, month] = value.split('-');
    this.form.year = Number(year);
    this.form.month = Number(month);
  }

  get currencyISO() {
    const candidate = this.countries.find((item) => item.countryId === this.form.countryIdTo);
    return candidate?.currencyISO;
  }

  created() {
    this.fetchCountries();
  }

  @Watch('value')
  onValueChange(val: boolean) {
    if (val) {
      if (this.isFirstLoad) {
        this.fetchCountries();
        this.isFirstLoad = false;
      }
    } else {
      this.form = {
        countryIdFrom: 0,
        countryIdTo: 0,
        month: null,
        value: null,
        year: null,
      };
      // @ts-ignore
      this.$refs.form.resetValidation();
    }
  }

  @Emit('input')
  toggleVisibility() {
    return !this.value;
  }

  async save() {
    try {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.loading = true;
        await ExternalIncomeService.save(this.form);
        this.toggleVisibility();
        this.$emit('saved');
      }
    } catch (e) {
      resolveError(e, 'save');
    } finally {
      this.loading = false;
    }
  }

  async fetchCountries() {
    try {
      this.countries = await AuthService.getRegisterForm();
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }
}
