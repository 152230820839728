import IExternalIncomesListParams from '@/types/IExternalIncomesListParams';
import ExternalIncomeRepository from '@/repositories/ExternalIncomeRepository';
import ExternalIncomeFactory from '@/factories/ExternalIncomeFactory';
import IExternalIncome from '@/types/IExternalIncome';
import IExternalIncomeForm from '@/types/IExternalIncomeForm';
import {replaceCommaToDot} from '@/utils/universalUtils';

export default class ExternalIncomeService {
  static async getAll(params: IExternalIncomesListParams)
    : Promise<{ incomes: IExternalIncome[], totalElements: number }> {
    const {data} = await ExternalIncomeRepository.getAll(params);

    return {
      incomes: data.content.map((item: any) => ExternalIncomeFactory.toExternalIncome(item)),
      totalElements: data.totalElements,
    };
  }

  static save(data: IExternalIncomeForm) {
    return ExternalIncomeRepository.save({
      ...data,
      value: replaceCommaToDot(data.value!),
    });
  }

  static updateValue(id: number, value: string) {
    return ExternalIncomeRepository.updateValue(id, value.replace(',', '.'));
  }
}
