import axios from 'axios';
import IExternalIncomesListParams from '@/types/IExternalIncomesListParams';
import IExternalIncomeForm from '@/types/IExternalIncomeForm';

export default class ExternalIncomeRepository {
  static getAll(params: IExternalIncomesListParams): Promise<any> {
    return axios.post('external-income/search', params);
  }

  static save(form: IExternalIncomeForm) {
    return axios.post('external-income', form);
  }

  static updateValue(externalIncomeID: number, newValue: string | number): Promise<any> {
    return axios.patch(`external-income/${externalIncomeID}/${newValue}`);
  }
}
