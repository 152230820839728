
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {IHash} from '@/types/IHash';
import ExternalIncomeService from '../../services/ExternalIncomeService';
import {resolveError} from '@/utils/notifications';
import {removeItems, replaceCommaToDot} from '@/utils/universalUtils';
import IExternalIncome from '../../types/IExternalIncome';
import IExternalIncomesListParams from '@/types/IExternalIncomesListParams';
import {price, required} from '@/utils/validationRules';
import {IDataTablePagination} from '@/types/IPagination';

@Component({name: 'ExternalIncomeTable'})
export default class ExternalIncomeTable extends Vue {
  @Prop()
  data!: IExternalIncome[];

  @Prop()
  params!: IExternalIncomesListParams;

  @Prop()
  loading!: boolean;

  incomes: IExternalIncome[] = [];

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_DATE',
      value: 'month',
    },
    {
      text: 'SYSTEM_COUNTRY_FROM_NAME',
      value: 'countryFromName',
    },
    {
      text: 'SYSTEM_COUNTRY_TO_NAME',
      value: 'countryToName',
    },
    {
      text: 'SYSTEM_VAT_VALUE',
      value: 'vatValue',
      sortable: false,
    },
    {
      text: 'SYSTEM_VAT_DECLARATION',
      value: 'vatDeclaration',
      sortable: false,
    },
    {
      text: 'SYSTEM_INCOME_LIMIT',
      value: 'incomeLimit',
      sortable: false,
    },
    {
      text: 'SYSTEM_CURRENCY',
      value: 'countryTo.currency.currencyISO',
    },
    {
      text: 'SYSTEM_TOTAL_INCOME',
      value: 'value',
    },
  ];
  savingIncomesIds: number[] = [];
  originalValues: IHash = {};
  changedValues: IHash = {};

  rules = {
    value: [required, price],
  };

  @Emit('fetchData')
  emitFetchData() {
    return;
  }

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  @Watch('data')
  onDataChanged(newData: IExternalIncome[]) {
    newData.forEach((item) => {
      this.originalValues[item.externalIncomeId] = replaceCommaToDot(item.value);
    });
    this.incomes = newData.map((item: IExternalIncome) => ({
      ...item,
      value: String(item.value).replace('.', ','),
    }));
  }

  @Emit('sort')
  sort(value: { sortBy: string[], sortDesc: string[] }) {
    this.params.sortBy = value.sortBy[0];
    this.params.sortDirection = value.sortDesc[0] ? 'DESC' : 'ASC';
    return this.params;
  }

  changeValue(id: number, newValue: string) {
    this.changedValues[id] = replaceCommaToDot(newValue);
  }

  async saveValue(id: number) {
    try {
      // @ts-ignore
      if (this.$refs[`form_${id}`].validate()
          && this.changedValues[id] !== undefined && this.originalValues[id] !== this.changedValues[id]) {
        this.savingIncomesIds.push(id);
        await ExternalIncomeService.updateValue(id, String(this.changedValues[id]));
        this.originalValues[id] = this.changedValues[id];
        delete this.changedValues[id];
      }
    } catch (e) {
      resolveError(e, 'edit');
    } finally {
      this.savingIncomesIds = removeItems(this.savingIncomesIds, id);
    }
  }

  returnOriginalValue(id: number) {
    const candidateIdx: number = this.incomes.findIndex((item) => item.externalIncomeId === id);
    if (candidateIdx >= 0) {
      this.incomes[candidateIdx].value = this.originalValues[id];
      delete this.changedValues[id];
    }
  }
}
