
import {Component, Vue} from 'vue-property-decorator';
import IExternalIncome from '@/types/IExternalIncome';
import IExternalIncomesListParams from '@/types/IExternalIncomesListParams';
import ExternalIncomeService from '@/services/ExternalIncomeService';
import {resolveError} from '@/utils/notifications';
import YearAndMonthFilter from '@/components/YearAndMonthFilter.vue';
import IExternalIncomesFilters from '@/types/IYearAndMonthFilter';
import ExternalIncomeTable from '@/components/ExternalIncome/ExternalIncomeTable.vue';
import ExternalIncomeForm from '@/components/ExternalIncome/ExternalIncomeForm.vue';
import {IDataTablePagination} from '@/types/IPagination';

@Component({
  name: 'ExternalIncomes',
  components: {
    ExternalIncomeForm,
    ExternalIncomeTable,
    YearAndMonthFilter,
  },
})
export default class ExternalIncomes extends Vue {
  isFormModalOpen: boolean = false;
  incomes: IExternalIncome[] = [];
  params: IExternalIncomesListParams = {
    month: null,
    year: null,
    page: 1,
    size: 10,
    totalElements: 0,
  };
  loading: boolean = false;

  get isYearSelected(): boolean {
    return !!(this.params.year);
  }

  created() {
    // this.fetchData()
  }

  onFiltersChanged(filters: IExternalIncomesFilters) {
    this.params = {...this.params, ...filters};
    this.fetchData();
  }

  paginationChanged(pagination: IDataTablePagination) {
    this.params.page = pagination.page;
    this.params.size = pagination.itemsPerPage;

    this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;
      const {
        incomes,
        totalElements,
      } = await ExternalIncomeService.getAll(this.params);
      this.incomes = incomes;
      this.params.totalElements = totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
